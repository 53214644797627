<template>
    <div class="core-checkbox">
        <div class="core-checkbox__input">
            <Checkbox
                v-model="checked"
                :id="name"
                :binary="true"
            />
            <mdicon
                v-if="checked"
                class="core-checkbox__icon"
                name="check"
                size="18"
            />
        </div>
        <label
            :for="name"
            class="text-200"
            >{{ $t(label) }}</label
        >
    </div>
</template>

<script>
import Checkbox from 'primevue/checkbox'

export default {
    name: 'CoreCheckbox',

    components: { Checkbox },

    props: {
        name: {
            type: String,
            required: true,
        },

        label: {
            type: String,
            required: true,
        },
    },

    computed: {
        checked: {
            get() {
                return this.$attrs.value
            },
            set(value) {
                this.$emit('input', value)
            },
        },
    },
}
</script>

<style lang="scss">
.core-checkbox {
    & .p-hidden-accessible {
        display: none;
    }

    & {
        display: flex;
        gap: 8px;
        align-items: center;
    }

    & .p-checkbox .p-checkbox-box {
        border-radius: 4px;
    }

    &__input {
        position: relative;
        cursor: pointer;
    }

    .p-checkbox-icon {
        display: none;
    }

    &__icon {
        font-size: 1.8rem !important;
        position: absolute;
        top: -1px;
        left: 2px;
        color: $cn-10;
        pointer-events: none;
    }
}
</style>
